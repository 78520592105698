import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Stack,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { Box, Tabs, Tab, Badge } from "@mui/material";
import Chart from "react-apexcharts";
import Controls from "../../Components/controls/Controls";
import {
  TableContainer,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/system";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  " td, th": {
    color: "white",
  },
}));
var Top5ProductsOptions = {
  series: [20, 80],
  chart: {
    width: 400,
    type: "donut",
    //color change
    colors: ["red", "green"],
  },
  dataLabels: {
    enabled: true,
  },
  options: {
    colors: ["#5C4742", "#A5978B"],
    // labels: [20, 80],
    //lables hide
    labels: ["Correct", "Incorrect"],
    colors: ["#2ecc71", "#C21E56"],
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Roboto",
              color: "white",
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: "13px",
              fontFamily: "Roboto",
              color: "white",
              offsetY: 36,
              formatter: function (val) {
                return val + "%" + "                              " + "Correct";
              },
            },
          },
        },
      },
    },
    // title: {
    //     text: "Gradient Donut with custom Start-angle",
    // },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  },
};
function TestAnalysis(props) {
  const { resultData } = props;

  const flagId = 1;
  const rows = [
    { id: 1, question: "Question 1", answer: "Answer 1", correct: "Correct 1" },
    { id: 2, question: "Question 2", answer: "Answer 2", correct: "Correct 2" },
    { id: 3, question: "Question 3", answer: "Answer 3", correct: "Correct 3" },
    { id: 4, question: "Question 4", answer: "Answer 4", correct: "Correct 4" },
  ];
  const [open, setOpen] = useState(false);

  const [chartValue, setChartValue] = useState(Top5ProductsOptions);
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    setChartValue((previousValues) => {
      let newValues = { ...previousValues };
      newValues.series = [resultData.yourScore, resultData.incorrect];
      return newValues;
    });
  }, [resultData]);
  return (
    <div
      style={{
        marginTop: "40px",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={8} md={5} lg={5}>
          <Chart
            options={{
              ...chartValue.options,
              // theme: { mode: "light" },
            }}
            series={chartValue.series}
            type="donut"
            height="300"
            //set color
            // colors={["red", "green"]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          lg={1}
          sx={{
            display: { xs: "block", sm: "none", md: "block", lg: "block" },
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={5}
          lg={5}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            marginTop: "10px",
          }}
        >
          <Typography className="resultHeader">Your Score</Typography>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "10px",
              // marginBottom: "10px"
            }}
          >
            <p className="fontLights analysisHeadding">Total Correct</p>
            <p className="fontLights ">{resultData.yourScore}</p>
          </Grid>
          <Divider className="divider" />
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <p className="fontLights analysisHeadding">Total Incorrect</p>
            <p className="fontLights ">{resultData.incorrect}</p>
          </Grid>
          <Divider className="divider" />

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              // marginTop: "10px"
            }}
          >
            <p className="fontLights analysisHeadding">Total Omitted</p>
            <p className="fontLights ">{resultData.omitted}</p>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: "80px",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            marginTop: "20px",
          }}
        >
          <TableContainer
            style={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "var(--main-bg)" }}>
                <TableRow>
                  <TableCell className="tableCell">NAME</TableCell>
                  <TableCell className="tableCell">TOTAL QUESTIONS</TableCell>
                  <TableCell className="tableCell">CORRECT</TableCell>
                  <TableCell className="tableCell">INCORRECT</TableCell>
                  <TableCell className="tableCell">OMITTED</TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="accordian">
                {resultData.tableData &&
                  resultData?.tableData.map((row, index) => {
                    return (
                      <>
                        <StyledTableRow
                          data-toggle="collapse"
                          data-target={"#demo" + index}
                          class="accordion-toggle"
                          sx={{
                            cursor: "pointer",
                          }}
                          aria-controls={"#demo" + index}
                          onClick={() => {
                            setIsExpanded((preval) => {
                              if (preval === index) {
                                return -1;
                              } else {
                                // getUsermemberListApi(row.id);
                                return index;
                              }
                            });
                          }}
                        >
                          <TableCell sx={{ color: "white" }}>
                            {row.unitName}
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            {row.countQuestion}
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            {row.correctAns}
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            {row.inCorrectAns}
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            {row.ommited}
                          </TableCell>
                        </StyledTableRow>

                        {resultData?.tableData.length ? (
                          resultData.tableData[index].topics.map(
                            (data, ind) => {
                              return (
                                <>
                                  {isExpanded === index && (
                                    <StyledTableRow
                                      sx={{
                                        backgroundColor:
                                          "var(--main-table-color)",
                                      }}
                                    >
                                      <TableCell
                                        sx={{ paddingLeft: "25px" }}
                                        className="fontLight"
                                      >
                                        {data.topicName}
                                      </TableCell>
                                      <TableCell
                                        sx={{ paddingLeft: "25px" }}
                                        className="fontLight"
                                      >
                                        {data.countQuestion}
                                      </TableCell>
                                      <TableCell
                                        sx={{ paddingLeft: "25px" }}
                                        className="fontLight"
                                      >
                                        {data.correctAns}
                                      </TableCell>
                                      <TableCell
                                        sx={{ paddingLeft: "25px" }}
                                        className="fontLight"
                                      >
                                        {data.inCorrectAns}
                                      </TableCell>
                                      <TableCell
                                        sx={{ paddingLeft: "25px" }}
                                        className="fontLight"
                                      >
                                        {data.ommited}
                                      </TableCell>
                                    </StyledTableRow>
                                  )}
                                </>
                              );
                            }
                          )
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "5px 0",
                              color: "red",
                            }}
                            id="msgdiv"
                          ></div>
                        )}
                      </>
                    );
                  })}
                {resultData?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "silver",
                        textAlign: "center",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "30px",
                      }}
                      colSpan="6"
                    >
                      No records to display
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}

export default TestAnalysis;
