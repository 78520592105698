import React, { useState, useEffect } from "react";
import "./userLogin.css";
import { useForm, Form } from "../../Utils/useForm";
import ToastNotification from "../../Utils/toastNotification";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  IconButton,
  Tooltip,
  Button,
  styled,
  useMediaQuery,
  Stack,
} from "@mui/material";
import loginImage from "../../assets/images/biobuddy-login-update.png";
import { loginUserApi } from "../../state/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import LoadingButton from "@mui/lab/LoadingButton";
import LoginIcon from "@mui/icons-material/Login";
import ForgotPassword from "./ForgotPassword";
import { store } from "../../state/store";
import Modal from "@mui/material/Modal";
import { Helmet } from "react-helmet";
import { testRunning } from "../../state/actions/exam";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1b3065",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};
const initialValues = { email: "", password: "" };
const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;

const StyledLeftSide = styled("div")(() => ({
  borderRadius: "10px",
  padding: "30px 25px",
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)",
}));
const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#007aff",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#0957DD",
    color: "white",
  },
}));
const StyledRightSide = styled("div")(() => ({
  borderRadius: "10px",
  marginTop: "1rem",
}));

const UserLogin = () => {
  const xs = useMediaQuery("@media (max-width:600px)");
  const Navigate = useNavigate();
  const { auth } = store.getState();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const cookies = new Cookies();
  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);

  // if (isAuthenticated) {
  //   if (auth.user.role == 1) {
  //     Navigate("/");
  //   }
  //   if (auth.user.role == 2) {
  //     if (state && state.cartId != null) {
  //       dispatch(testRunning(true));

  //       state && state.cartId != null
  //         ? Navigate(`/checkout`, {
  //           state: {
  //             cartId: state.cartId,
  //             plan: state.planName,
  //             planExpirationDuration: state.planExpirationDuration,
  //             planPrice: state.planPrice,
  //           },
  //         })
  //         : Navigate("/");
  //     } else {
  //       Navigate("/welcome");
  //     }
  //   }
  // }

  useEffect(() => {
    const userEmail = cookies.get("email");
    const userPassword = cookies.get("password");
    if (userEmail !== "" && userPassword !== "") {
      setValues({
        ...values,
        email: userEmail,
        password: userPassword,
      });
    }
  }, []);
  useEffect(() => { }, [loadingButton]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      if (fieldValues.email !== "") {
        if (!/$^|.+@.+..+/.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("password" in fieldValues) {
      if (fieldValues.password !== "") {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 characters";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp.password = "";
              }
            }
          }
        }
      } else {
        temp.password = "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const getauthUser = JSON.parse(localStorage.getItem("authUser"))

  if (getauthUser) {

    console.log("getauthUser", getauthUser)

    if (getauthUser?.role == 1) {
      Navigate("/");
    } else {
      Navigate("/welcome");
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setLoadingButton(!loadingButton);
      const LoginResponse = await dispatch(
        loginUserApi(
          {
            email: values.email,
            password: values.password,
          },
          rememberMe
        )
      );
      if (LoginResponse && LoginResponse.status === true) {
        setLoadingButton(!loadingButton);
        setNotify({
          isOpen: true,
          message: LoginResponse.message || "Login Successfully.",
          type: "success",
        });
      } else {
        setLoadingButton(false);
        setNotify({
          isOpen: true,
          message: LoginResponse.message || "Login Fail.",
          type: "error",
        });
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login To Your Account | BioBuddy</title>
        <meta name="description" content='Sign in using your BioBuddy account login or register a new account. Create a free account to get started.' />
      </Helmet>
      <Grid
        container
        className="login-grid"
        spacing={1}
        sx={{
          backgroundColor: "#0C0E2E",
        }}
      >
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ backgroundColor: "#171D53" }}
          style={{
            paddingTop: "0px",
          }}
        >
          <StyledLeftSide>
            <Container>
              <Box>
                <Box
                  sx={{
                    display: { xs: "block", sm: "none" },
                  }}
                >
                  <img src={loginImage} alt="Login Background" />
                </Box>
                <Typography
                  component={"h4"}
                  className="fontLight"
                  sx={{
                    display: "inline-block",
                    fontSize: "36px",
                    fontWeight: "600",
                  }}
                >
                  Login
                </Typography>
                <br />
                <Typography
                  className="fontLight"
                  sx={{
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "400",
                    maxWidth: '370px'
                  }}
                >
                  Sign in using your BioBuddy account login or{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      color: '#1864ca',
                      fontWeight: "600",
                    }}
                    className="mt-3"
                    onClick={() =>
                      Navigate("/register", {
                        state: {
                          id: state && state.id != null ? state.id : null,
                          cartId:
                            state && state.cartId != null ? state.cartId : null,
                        },
                      })
                    }
                  >
                    register a new account.
                  </span>
                </Typography>
              </Box>
              <Form onSubmit={handleSubmit}>
                <Box sx={{ mt: 2 }}>
                  <StyledLabel className="fontLight">Email</StyledLabel>
                  <WhiteBorderTextField
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    sx={{
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "white",
                        },
                      },

                      mt: 1,
                      mr: 3,
                      borderRadius: "8px",
                    }}
                    size="small"
                    fullWidth
                  />
                  {Boolean(errors.email) ? (
                    <p
                      style={{
                        color: "#fc9494",
                        marginTop: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.email}
                    </p>
                  ) : (
                    ""
                  )}
                </Box>
                <Box sx={{ mt: 2 }}>
                  <StyledLabel className="fontLight">Password</StyledLabel>
                  <WhiteBorderTextField
                    name="password"
                    value={values.password}
                    onChange={handleInputChange}
                    type={showPassword ? "text" : "password"}
                    sx={{
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "white",
                        },
                      },

                      mt: 1,
                      mr: 3,
                      borderRadius: "8px",
                    }}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          className="borderLight"
                          position="start"
                          sx={{ m: 0 }}
                        >
                          <Tooltip
                            title={
                              showPassword ? "Hide Password" : "Show Password"
                            }
                          >
                            <IconButton
                              sx={{ color: "white" }}
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {Boolean(errors.password) ? (
                    <p
                      style={{
                        color: "#fc9494",
                        marginTop: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.password}
                    </p>
                  ) : (
                    ""
                  )}
                </Box>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ mt: 2 }}
                >
                  <FormGroup>
                    <FormControlLabel
                      className="fontLight"
                      label="Remember me"
                      control={
                        <Checkbox
                          sx={{ color: "white" }}
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                      }
                    />
                  </FormGroup>
                  <Button
                    onClick={handleOpen}
                    sx={{
                      textTransform: "capitalize",
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "0.5rem",
                        lg: "1rem",
                      },
                    }}
                  >
                    Forgot Password ?
                  </Button>
                </Stack>

                <LoadingButton
                  style={{
                    backgroundColor: "#007aff",
                    color: "white",
                    fontSize: "16px",
                    padding: "8px 20px",
                    "&:hover": {
                      backgroundColor: "#0957DD",
                      color: "white",
                    },
                  }}
                  sx={{ mt: 3 }}
                  loading={loadingButton}
                  loadingPosition="start"
                  startIcon={<LoginIcon />}
                  variant="contained"
                  type="submit"
                >
                  Login
                </LoadingButton>
              </Form>
            </Container>
          </StyledLeftSide>
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{
            display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
            marginTop: "0",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StyledRightSide
            style={{
              padding: "20px 20px",
              textAlign: "center",
            }}
          >
            <img
              src={loginImage}
              className="login-img"
              alt="Login Background"
              sx={{
                width: { xs: "100%", md: "80%" },
                margin: "auto",
              }}
            />
          </StyledRightSide>
        </Grid>
      </Grid>
      <Modal
        keepMounted
        open={open}
        aria-labelledby="create-auction-zero-step-buyer-aspect"
        aria-describedby="create-auction-zero-step-buyer-aspect-desc"
      >
        <Box sx={style}>
          <ForgotPassword handleClose={handleClose} setNotify={setNotify} />
        </Box>
      </Modal>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default UserLogin;
