import React, { useState, useEffect, useRef } from "react";
import { Box, Paper, Grid, Tooltip, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import Divider from "@mui/material/Divider";
import { videoLibraryList } from "../../state/actions/videoLibraryActions";
import { styled } from "@mui/system";
import { deleteFaq, changeStatus } from "../../state/actions/faqAction";
import { deleteVideoLibrary } from "../../state/actions/videoLibraryActions";
import Vimeo from "@u-wave/react-vimeo";
import ModalVideo from "react-modal-video";
import Modal from "@mui/material/Modal";
import Controls from "../../Components/controls/Controls";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { BsCheck } from 'react-icons/bs';
import "./uservideo.css";
import { updateWatchTime } from "../../state/actions/welcomeAction";
const style = {
  border: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '400px', sm: '560px', md: '800px' },
  bgcolor: "var(--main-bg)",
  // minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: { xs: '37px', md: '20px' },
  boxShadow: "none",
  backgroundColor: "transparent",
  outline: "none",
};

const styles = {
  border: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "var(--main-bg)",
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 1,
  boxShadow: "none",
  backgroundColor: "transparent",
  outline: "none",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1b3065",

  boxShadow: 24,
  p: 2,
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#404250",
  },
  " td, th": {
    color: "#efefef",
  },
}));


const UserVideoLibrary = () => {
  const { auth } = useSelector((state) => state);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const Navigate = useNavigate();
  const [videoUrlLink, setVideoUrlLink] = useState("");
  const [openErrorModel, setOpenErrorModel] = useState(false);
  const handleErrorModel = () => setOpenErrorModel(true);
  const [videoModal, setVideoModal] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [isActive, setIsActive] = useState("");
  const [topicId, setTopicId] = useState("");

  // const handleClose = () => setOpen(false);
  const handleCloseError = () => setOpenErrorModel(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [videolist, setVideolist] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [videoWatchTime, setVideoWatchTime] = useState(0);
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const videoData = async (pageNum) => {
    setOpenBreakdrop(true);
    const queryString = `?page=${pageNum}&size=${rowsPerPage}&search=${search}`;
    const res = await videoLibraryList(queryString);
    if (res && res.status === true) {
      setVideolist(res.data);
      setTotalRecords(res.totalRecord);
      setPage(pageNum);
      setTimeout(() => {
        setOpenBreakdrop(false);
      }, 2000);
    } else {
      setVideolist([]);
      setOpenBreakdrop(false);
    }
  };
  const handleTimeUpdate = (time) => {
    setCurrentTime(time);
  };

  const handleReady = (player) => {
    player.getDuration().then((duration) => {
      // setDuration(duration);
    });
    setTimeout(() => {
      setOpenBreakdrop(false);
    }, 1000);
  };
  const handleClose = () => {
    handleCloseVideo();
    setVideoModal(false);
    // setOpenBreakdrop(false);
  };
  const handleCloseVideo = async () => {
    setOpenBreakdrop(true);
    //current time in sec
    let sec = currentTime?.seconds;
    let percent = (currentTime?.percent * 100)
    const payload = {
      watchTime: Math.ceil(percent),
      watchTimeSec: sec,
      unitId: isActive,
      videoId: videoUrlLink,
      topicId: topicId,
    };
    const res = await updateWatchTime(payload);

    if (res && res.status) {
      //if last then call list api     
      videoData();
    }
  };
  useEffect(() => {
    videoData();
    if (auth?.user?.planDetail?.planPrice == "0.00") {
      setErrMsg(true);
    }
  }, [auth]);

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const openVideo = (allowInTest, video) => {
    setOpenBreakdrop(true);
    setIsActive(video.unitId);
    setTopicId(video.topicId);

    if (auth?.user?.planDetail?.planPrice == "0.00") {
      if (allowInTest == 1) {
        setOpen(true);
        setVideoModal(true)

      } else {
        setOpenErrorModel(true);
        setErrMsg(true);
        setOpenBreakdrop(false);

      }
    } else {
      setOpen(true);
      setVideoModal(true)

    }
  };
  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <Typography
              className="fontLight"
              sx={{ fontSize: "30px", fontWeight: "bold" }}
            >
              Video Library
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
          color: "black",
          padding: "10px",
          marginTop: "40px",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        {videolist.length > 0 &&
          videolist.map((item, index) => {
            return (
              <>
                <Grid item xs={12}>
                  <Typography
                    key={index}
                    className="fontLight"
                    variant="h5"
                    component="div"
                    sx={{ flexGrow: 1 }}
                    style={{ marginTop: "20px", marginLeft: "10px" }}
                  >
                    {item.name}{" "}
                    <span
                      style={{
                        color: "#FC9494",
                        fontSize: "16px",
                        cursor: "pointer",
                        paddingLeft: "5px",
                      }}
                    // onClick={() => Navigate("/buy-now")}
                    >
                    </span>
                  </Typography>
                  <Divider sx={{ bgcolor: "white", mt: 1 }} />
                </Grid>
                {item.video &&
                  item.video.length > 0 &&
                  item.video.map((video, topicIndex) => {
                    let test = video.videoLink;
                    let videoUrl = "";
                    if (video.videoLink.startsWith("http://") || video.videoLink.startsWith("https://")) {
                      videoUrl = new URL(video?.videoLink)?.pathname.split("/");
                    }
                    let topic = video.topicId.split(",");
                    return (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            key={topicIndex}
                            className="fontLight"
                            component="div"
                            sx={{ flexGrow: 1 }}
                            style={{
                              marginTop: "20px",
                              fontSize: "18px",
                              marginLeft: "10px",
                            }}
                          >
                            {topic.join(", ")}{" "}
                          </Typography>
                        </Grid>

                        <Grid
                          container
                          style={{
                            marginTop: "0.5rem",
                            padding: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Grid xs={12} md={4} sm={4} lg={4} xl={4}>
                            {video && video.watchTime == 100 ? (

                              <div
                                className={
                                  errMsg && video.allowInTest == 0
                                    ? "videoNotPlayIcon"
                                    : "videoPlayIcon"
                                }
                                onClick={() => {
                                  openVideo(video.allowInTest, video);
                                  setVideoUrlLink(test);
                                  setVideoWatchTime(Number(video.watchTimeSec));
                                }}
                                style={{ position: 'relative', border: '1px solid #999999', borderRadius: 'unset' }}
                              >
                                <img
                                  src={video.thumbnail}
                                  className={
                                    errMsg && video.allowInTest == 0
                                      ? "videoThumb disable"
                                      : "videoThumb"
                                  }
                                  style={{ cursor: "pointer", borderRadius: 'unset' }}
                                />
                                <Box sx={{
                                  position: 'absolute',
                                  width: '30px',
                                  height: '30px',
                                  top: '-10px',
                                  right: '-10px',
                                  background: '#008900',
                                  borderRadius: '50%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}>

                                  <BsCheck style={{
                                    fontSize: '25px',
                                    color: '#fff',
                                  }} />
                                </Box>
                                <Divider sx={{ borderColor: "#008900!important", borderWidth: "6px", position: 'absolute', width: '100%', bottom: '0px' }}></Divider>
                                <a></a>
                              </div>
                            ) : (
                              <div
                                className={
                                  errMsg && video.allowInTest == 0
                                    ? "videoNotPlayIcon"
                                    : "videoPlayIcon"
                                }
                                onClick={() => {
                                  openVideo(video.allowInTest, video);
                                  setVideoUrlLink(test);
                                  setVideoWatchTime(Number(video.watchTimeSec));
                                }}
                                style={{ position: 'relative', border: '1px solid #999999', borderRadius: 'unset' }}
                              >
                                <img

                                  src={video.thumbnail}
                                  className={
                                    errMsg && video.allowInTest == 0
                                      ? "videoThumb disable"
                                      : "videoThumb"
                                  }
                                  style={{ cursor: "pointer", borderRadius: 'unset' }}
                                />
                                {video.watchTime == 0 ? (null) : (<Divider sx={{ borderColor: "#008900!important", borderWidth: "6px", position: 'absolute', width: `${video.watchTime}%`, bottom: '0px' }}></Divider>)}

                                {/* <a></a> */}
                                {console.log("item.watchTime", video.watchTime)}
                              </div>
                            )}

                          </Grid>
                          <Grid sx={{ display: { xs: "block", sm: "none" } }} xs={12}>

                            <Typography
                              key={index}
                              className="fontLight"
                              sx={{

                                paddingTop: "10px"
                              }}
                            >
                              {video.title}


                            </Typography>

                          </Grid>
                          <Grid sx={{ display: { xs: "none", sm: "block" } }} xs={8} md={8} sm={7} lg={8} xl={8}>
                            <Typography
                              key={index}
                              className="fontLight"
                              sx={{
                                paddingLeft: {
                                  xs: "10px",
                                  sm: "15px",
                                  md: "0px",
                                },
                              }}
                            >
                              {video.title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
              </>
            );
          })}
      </div>

      <Modal
        keepMounted
        open={openErrorModel}
        onClose={handleCloseError}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <label className="fontLight">
            Upgrade your plan to watch more videos.
          </label>
          <Grid container style={{ marginTop: "1rem", justifyContent: "end" }}>
            <Grid item xs={7} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseError} />
            </Grid>
            <Grid item xs={4} className="text-end">
              {/* <Controls.Button
                text="Buy-now"
                onClick={() => Navigate("/buy-now")}
              /> */}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={videoModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {!openBackdrop && (<CloseIcon onClick={handleClose} sx={{ fontSize: "30px", color: "white", cursor: "pointer" }} />
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <Box sx={{ width: "100%", height: "100%" }} className='vimeo_video'>
              <Vimeo
                video={videoUrlLink}
                onReady={handleReady}
                onTimeUpdate={handleTimeUpdate}
                autoplay
                onEnd={handleCloseVideo}
                onError={() => {
                  setOpenBreakdrop(false);
                  setNotify({
                    isOpen: true,
                    message: "Video Not Found",
                    type: "error",
                  });
                }}
                //start with i will gave time in percentage convert in sec
                start={videoWatchTime}
                //width less then 991 then video mute other wise not mute
                muted={width > 991 ? false : true}
              // width={703}
              // height={400}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UserVideoLibrary;
