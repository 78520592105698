import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ListItemIcon, ListItemText } from "@mui/material";
import {
  Search as SearchIcon,
  Notifications as NotificationsIcon,
  ExpandMore,
  ExpandLess,
  Logout as LogoutIcon,
  Person,
  AccountCircle,
} from "@mui/icons-material";
import {
  AppBar,
  Toolbar,
  Button,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { logoutApi } from "../../state/actions/authActions";
import { store } from "../../state/store";
import { useDispatch } from "react-redux";
import "./Header.css";
import MenuIcon from "@mui/icons-material/Menu";
import { userSessionMentaion } from "../../state/actions/headerAction";
const StyledLogin = styled(Button)(() => ({
  border: "1px solid",
  borderColor: "#fc9494",
  borderRadius: "10px",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    color: "#fc9494",
  },
}));
const Header = (props) => {
  const { showNavbar, setShowNavbar, isOpen, setIsOpen } = props;
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [planExpire, setPlanExpire] = useState(null);
  const [admin, setAdmin] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { auth } = store.getState();
  const sidebarRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const body = document.body;

    if (showNavbar) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "unset";
    }

    return () => {
      body.style.overflow = "unset";
    };
  }, [showNavbar]);
  // if (!auth.isAuthenticated) {
  //   navigate("/login");
  // }
  const logoutAdmin = async () => {
    const res = await userSessionMentaion();
    if (res.status == true) {
      localStorage.removeItem("authUser");
      dispatch(logoutApi());
      navigate("/");
    }
  };

  
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let timeout = setInterval(() => {
      let { auth } = store.getState();
      if (auth.user) {
        // if data is on store then we have to update the state of user with userdata and stop the timeout
        setAdmin(auth.user);
        setPlanExpire(auth?.user?.expirationLeftPeriod);
        stoptimeout();
      }
    }, 500);
    function stoptimeout() {
      clearInterval(timeout);
    }
  }, []);


  return (
    <AppBar
    ref={sidebarRef}
      position="static"
      sx={{
        mt: 2,
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      {/* <Toolbar
        sx={{ paddingLeft: "5px !important", marginBottom: { xs: "1rem" } }}
      >
        <Box sx={{ flexGrow: 1, textAlign: "center" }}>
          <div className="menu-icon" onClick={handleShowNavbar}>
            <MenuIcon />
            <span style={{ paddingTop: "2px", marginLeft: "5px" }}> MENU</span>
          </div>
          {auth?.user?.role == 2 && planExpire == 0 ? (
            <StyledLogin
              onClick={() => navigate("/buy-now")}
              sx={{
                display: { xs: "none", sm: "none", md: "inline" },
                textTransform: "capitalize",
              }}
            >
              Your plan has expired.
            </StyledLogin>
          ) : auth?.user?.role == 2 && planExpire != null && planExpire < 7 ? (
            <StyledLogin
              onClick={() => navigate("/buy-now")}
              sx={{ display: { xs: "none", sm: "none", md: "inline" } }}
            >
              Your plan is set to expire in{" "}
              {planExpire > 0 ? ` ${planExpire}` : ""} days. Please upgrade to
              continue.
            </StyledLogin>
          ) : (
            ""
          )}
        </Box>

        <Box sx={{ display: "flex" }}>
          <Button
            sx={{
              backgroundColor: "var(--main-bg)",
              borderRadius: "10px",
              color: "black",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#136cf0",
              },
            }}
            onClick={handleClick}
          >
            <Avatar src={<AccountCircle />} /> &nbsp;
            <Typography sx={{ mx: 0.5 }}>{admin.name || "Bio Buddy"}</Typography>
            <Typography className="fontLight" sx={{ mx: 0.5 }}>
              {admin.name}
            </Typography>
            {open ? (
              <ExpandLess className="fontLight" sx={{ mx: 0.5 }} />
            ) : (
              <ExpandMore className="fontLight" sx={{ mx: 0.5 }} />
            )}
          </Button>
          <Menu
            sx={{
              mt: 0.3,
              "&#user-profile .MuiPaper-root ": {
                width: "205px",
                borderRadius: "10px",
              },
            }}
            id="user-profile"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              sx={{ mt: 1 }}
              onClick={() => {
                navigate("/profile");
                handleClose();
              }}
            >
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
            <MenuItem sx={{ mt: 1 }} onClick={() => logoutAdmin()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar> */}
      <div style={{position:"absolute",right:"50px"}}>
        <button className="menu-button" onClick={toggleSidebar}>
          <MenuIcon />
        </button>
      </div>
      {auth?.user?.role == 2 && planExpire == 0 ? (
        <StyledLogin
          // onClick={() => navigate("/buy-now")}
          sx={{
            display: { xs: "block", sm: "none", md: "none" },
            textTransform: "capitalize",
          }}
        >
          Your plan has expired.
        </StyledLogin>
      ) : auth?.user?.role == 2 && planExpire != null && planExpire < 7 ? (
        <StyledLogin
          sx={{
            display: { xs: "block", sm: "none", md: "none" },
            textTransform: "capitalize",
            width: "80%",
            margin: "auto",
          }}
          // onClick={() => navigate("/buy-now")}
        >
          Your plan is set to expire in {planExpire > 0 ? ` ${planExpire}` : ""}{" "}
          days. Please upgrade to continue.
        </StyledLogin>
      ) : (
        ""
      )}
    </AppBar>
  );
};

export default Header;
