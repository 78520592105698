import React, { useState } from "react";
import "./styles/VideoTable.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const VideoTable = ({ data, sortConfig, setSortConfig }) => {
  const [selectedUnit, setSelectedUnit] = useState(null);

  const handleRowClick = (unitName) => {
    setSelectedUnit(selectedUnit === unitName ? null : unitName);
  };

  return (
    <div className="table-container">
      <table className="newtable">

        <thead className="" >
          <tr className="">
            <th className="tableheader " onClick={() => setSortConfig({ key: "unitName", direction: sortConfig && sortConfig.key === "unitName" && sortConfig.direction === "asc" ? "desc" : "asc" })}>
              Unit {sortConfig && sortConfig.key === "unitName" && sortConfig.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
            </th>
            <th className="tableheader " onClick={() => setSortConfig({ key: "totalViews", direction: sortConfig && sortConfig.key === "totalViews" && sortConfig.direction === "asc" ? "desc" : "asc" })}>
              Total Video Views {sortConfig && sortConfig.key === "totalViews" && sortConfig.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
            </th>
            <th className="tableheader " onClick={() => setSortConfig({ key: "totalCompletedVideo", direction: sortConfig && sortConfig.key === "totalCompletedVideo" && sortConfig.direction === "asc" ? "desc" : "asc" })}>
              Total Video Completed{" "} {sortConfig && sortConfig.key === "totalCompletedVideo" && sortConfig.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
            </th>
            <th className="tableheader  " onClick={() => setSortConfig({ key: "totalWatchTime", direction: sortConfig && sortConfig.key === "totalWatchTime" && sortConfig.direction === "asc" ? "desc" : "asc" })}>
              Total Video Watch Time{" "} {sortConfig && sortConfig.key === "totalWatchTime" && sortConfig.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
            </th>
            <th className="tableheader " onClick={() => setSortConfig({ key: "averageWatchTime", direction: sortConfig && sortConfig.key === "averageWatchTime" && sortConfig.direction === "asc" ? "desc" : "asc" })}>
              Avg. Video Watch Time{" "} {sortConfig && sortConfig.key === "averageWatchTime" && sortConfig.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
            </th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 && data.map((item, index) => (
            <React.Fragment key={index}>
              <tr
                onClick={() => handleRowClick(item.unitName)}
                style={{ backgroundColor: "#191e26" }}
                className={selectedUnit === item.unitName ? "selected" : ""}
              >
                <td style={{ textAlign: "left" }} className="tablebody">
                  {item.unitName}
                </td>
                <td className="tablebody">{item.totalViews}</td>
                <td className="tablebody">{item.totalCompletedVideo}</td>
                <td className="tablebody">{((item.totalWatchTime) / 60).toFixed(2) + " min"}</td>
                <td className="tablebody">{(item.averageWatchTime / 60).toFixed(2) + " min"}</td>
              </tr>
              {selectedUnit === item.unitName &&
                item && item.topics && item.topics.map((subItem, subIndex) => (
                  <tr
                    key={`${index}-${subIndex}`}
                    className="subdata selected"
                    style={{ backgroundColor: "#0d1216" }}
                  >
                    <td
                      className="tablebody"
                      style={{
                        textAlign: "left",
                        alignItems: "start",
                        width: "fit-content",
                      }}
                    >
                      {subItem.topicName}
                    </td>
                    <td className="tablebody">{subItem.totalViews}</td>
                    <td className="tablebody">{subItem.totalCompletedVideo}</td>
                    <td className="tablebody">{(subItem.totalWatchTime / 60).toFixed(2) + " min"}</td>
                    < td className="tablebody">{(subItem.averageWatchTime / 60).toFixed(2) + " min"}</td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VideoTable;
